.square-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.square {
  width: 215px;
  height: 115px;
  position: relative;
}

.rectangle {
  position: absolute;
  width: 40px;
  height: 100px;
  background-color: transparent;
  border-radius: 25px;
  border: 10px solid #000;
}

.top-left {
  border-color: #50c5b7;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  animation: animateRectangleTopLeft 1.5s infinite ease-in-out;
}

.top-right {
  border-color: #9cec5b;
  top: 0;
  right: 0;
  transform: rotate(-45deg);
  animation: animateRectangleTopRight 1.5s infinite ease-in-out;
}

.bottom-left {
  border-color: #03035b;
  bottom: 0;
  left: 0;
  transform: rotate(-45deg);
  animation: animateRectangleBottomLeft 1.5s infinite ease-in-out;
}

.bottom-right {
  border-color: #6184d8;
  bottom: 0;
  right: 0;
  transform: rotate(45deg);
  animation: animateRectangleBottomRight 1.5s infinite ease-in-out;
}

@keyframes animateRectangleTopLeft {
  0%,
  100% {
    transform: rotate(45deg) translate(-50%, -50%) scale(1);
  }
  50% {
    transform: rotate(45deg) translate(-50%, -50%) scale(1.2);
  }
}

@keyframes animateRectangleTopRight {
  0%,
  100% {
    transform: rotate(-45deg) translate(50%, -50%) scale(1);
  }
  50% {
    transform: rotate(-45deg) translate(50%, -50%) scale(1.2);
  }
}

@keyframes animateRectangleBottomLeft {
  0%,
  100% {
    transform: rotate(-45deg) translate(-50%, 50%) scale(1);
  }
  50% {
    transform: rotate(-45deg) translate(-50%, 50%) scale(1.2);
  }
}

@keyframes animateRectangleBottomRight {
  0%,
  100% {
    transform: rotate(45deg) translate(50%, 50%) scale(1);
  }
  50% {
    transform: rotate(45deg) translate(50%, 50%) scale(1.2);
  }
}
